import React, { useRef } from "react";
import * as THREE from "three";
import { useThree, Vector3, Euler, useFrame } from "@react-three/fiber";
import { GLTF } from "three-stdlib";
import { useGLTF, useTexture, Html } from "@react-three/drei";
import useMediaQuery from "../hooks/useMediaQuery";

type GLTFResult = GLTF & {
  nodes: {
    ['ds-book-cover']: THREE.Mesh
    ['ds-book-pages']: THREE.Mesh
    ['ds-book-ligature-bottom']: THREE.Mesh
    ['ds-book-ligature-top']: THREE.Mesh
  };
}

interface IProps {
  group?: JSX.IntrinsicElements["group"];
  colorCover?: string | undefined;
  urlTexture?: string | undefined;
  position?: Vector3 | undefined;
  rotation?: Euler | undefined;
  scale?: Vector3 | undefined;
  link?: JSX.Element | undefined; 
}

export default function BookModel({...props}: IProps) {
  const group = useRef<THREE.Group>(null!)
  {/* const [hovered, spread] = useHover(); */}
  
  // Animate model
  useFrame((state) => {
    const t = state.clock.getElapsedTime()
    group.current.rotation.z = -0.2 - (1 + Math.sin(t / 1.5)) / 20
    group.current.rotation.x = Math.cos(t / 4) / 8
    group.current.rotation.y = Math.sin(t / 4) / 8
    group.current.position.y = (1 + Math.sin(t / 1.5)) / 10
  })
  
  const { nodes } = useGLTF('/models/lp-book-compressed.glb') as GLTFResult
  const { viewport } = useThree();
  
  const texture = useTexture(props.urlTexture as string);
  texture.flipY = false; 
  
  const isDesktop = useMediaQuery('(min-width: 960px)');
  
  return (
    <>    
      <group ref={group} {...props} dispose={null} 
        scale={isDesktop? [(viewport.width / 8), (viewport.width / 8), (viewport.width / 9.5)] : [(viewport.width / 7), (viewport.width / 7), (viewport.width / 8.5)]}
      >
          <mesh geometry={nodes['ds-book-cover'].geometry} material={nodes['ds-book-cover'].material} rotation={[Math.PI / 2, 0, 0]} scale={[0.05, 0.07, 0.05]}>
            <meshPhongMaterial
              attach="material"
              shininess={50} 
              //color={props.colorCover}
              map={texture as THREE.Texture}
              side={THREE.FrontSide}
            />
            <Html className="label" center>
              {props.link}
            </Html> 
          </mesh>
          <mesh geometry={nodes['ds-book-pages'].geometry} material={nodes['ds-book-pages'].material} rotation={[Math.PI / 2, 0, 0]} scale={[0.05, 0.07, 0.05]}>
            <meshPhongMaterial
              attach="material"
              color={"#f3f3f3"}
              shininess={50}
            />
          </mesh>
          <mesh geometry={nodes['ds-book-ligature-bottom'].geometry} material={nodes['ds-book-ligature-bottom'].material} rotation={[Math.PI / 2, 0, 0]} scale={[0.05, 0.07, 0.05]} />
          <mesh geometry={nodes['ds-book-ligature-top'].geometry} material={nodes['ds-book-ligature-top'].material} rotation={[Math.PI / 2, 0, 0]} scale={[0.05, 0.07, 0.05]} />
      </group>
    </>
  )
}

useGLTF.preload('/models/lp-book-compressed.glb')

{/* function useHover() {
    const [hovered, hover] = useState(false)
    return [hovered, { onPointerOver: (e: UIEvent<HTMLDivElement>) => (e.stopPropagation(), hover(true)), onPointerOut: () => hover(false) }]
 } */}