import React, { Suspense, useContext } from "react";
import { Canvas } from "@react-three/fiber";
import { useContextBridge, OrbitControls, Html, useProgress } from "@react-three/drei";
import styled from "styled-components";
import BookModel from "../components/BookModel";

interface ColorProps {
  coverColor: string;
  textColor: string;
  urlTexture: string;
}

type ThemeContext = { colors: { cover: string; text: string } };
const ThemeContext = React.createContext<ThemeContext>(null!);

function Loader() {
  const { progress } = useProgress()
  return <Html center>{progress} % loaded</Html>
}

const BookScene: React.FC<ColorProps> = React.memo(({ coverColor, textColor, urlTexture }) => {
  const SceneWrapper = React.memo(() => {
    const ContextBridge = useContextBridge(ThemeContext);
    const theme = useContext(ThemeContext);
    return (
      <Canvas
        mode="concurrent"
        camera={{ zoom: 3, position: [20, 10, 40], fov: 45 }}
        dpr={[1, 2]}
        flat
        resize={{ scroll: false }}
      >
        <ambientLight color={0xf3f3f3} />
        <pointLight
          position={[0, 0, 200]}
          color={0xffffff}
          intensity={0.25}
          distance={1000}
          castShadow={true}
        />
        <directionalLight
          position={[1, -1, 1]}
          color={0xf7e7e7}
          intensity={0.25}
        />
        <ContextBridge>
          <Suspense fallback={<Loader />}>
            <BookModel
              urlTexture={urlTexture}
              position={[0, 0, 0]}
              rotation={[-0.75, -0.05, -5]}
              colorCover={theme.colors.cover}
            />
          </Suspense>
        </ContextBridge>
        <OrbitControls minPolarAngle={Math.PI / 2} maxPolarAngle={Math.PI / 2} enablePan={false} enableZoom={false} />
      </Canvas>
    );
  });

  return (
    <ThemeContext.Provider value={{ colors: { cover: coverColor, text: textColor } }}>
      <CanvasWrapper>
        <SceneWrapper />
      </CanvasWrapper>
    </ThemeContext.Provider>
  );
});

const CanvasWrapper = styled.div`
  width: 88vw;
  right: -6vw;
  aspect-ratio: 1/1;
  position: relative;
  z-index: 0;

  * > canvas {
    touch-action: auto!important;
    pointer-events: none;
  }

  @media only screen and (min-width: 52rem) {
    position: absolute;
    width: 50vw;
    top: -25%;
    right: -25%;
  }

  @media only screen and (min-width: 80rem) {
    * > canvas {
      touch-action: auto!important;
      pointer-events: auto;
    }
  }

  @media only screen and (min-width: 120rem) {
    position: absolute;
    width: 50vw;
    top: -12vw;
  }
`;

export default BookScene;
