/** @jsx jsx */
import { jsx, useThemeUI } from "theme-ui";
import { Fragment, useEffect } from "react";
import ProjectTitleListing from "../../../components/ProjectTitleListing";
import Hero from "../../../components/Hero";
import { Heading, Flex, Box, Text, Link } from "rebass";
import LPFilterPattern from "../../../img/lapierre/LP-Filter-pattern.gif";
import ProjectFadeInTitle from "../../../components/ProjectFadeInTitle";
import { StaticImage } from "gatsby-plugin-image";
import UCarouselZoom from "../../../img/ecommerce/UCarouselZoom.gif";
import Carousel from "../../../components/Carousel";
import imgCover1 from "../../../img/ds/ds-theming.png";
import imgCover2 from "../../../img/ds/ds-theming-dark-light.png";
import imgCover3 from "../../../img/ds/ds-theming-design-tokens.png";
import imgCover4 from "../../../img/ds/ds-theming-typography.png";
import imgCover5 from "../../../img/ds/ds-theming-values-table.png";
import BackToTop from "../../../components/BackToTop";
import CustomerInsights from "../../../icons/CustomerInsights";
import EcomPlatform from "../../../icons/EcomPlatform";
import EcomBestDesignPractices from "../../../icons/EcomBestDesignPractices";
import CompanyProjects from "../../../components/CompanyProjects";
import BookScene from "../../../components/BookScene";

import Layout from "../../../layouts/";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import ThemeColorSetter from "../../../components/ThemeColorSetter"; 

function Project() {
  const context = useThemeUI();
  const { theme } = context;
  const projectBgColor = theme?.colors?.accellProjectBgECom as string;
  const projectTextColor = theme?.colors?.accellProjectTextECom as string;
  const themeColor = theme?.colors?.accellProjectBgECom as string;
  const themeColorBg = theme?.colors?.background as string;
  
  useEffect(() => {
    const handleScroll = () => {
      const heroSection = document.getElementById("hero-section");
      if (heroSection) {
        const rect = heroSection.getBoundingClientRect();
        if (rect.bottom > 0) {
          document.body.classList.add("hero-visible");
          document.body.classList.remove("hero-hidden");
        } else {
          document.body.classList.add("hero-hidden");
          document.body.classList.remove("hero-visible");
        }
      } else {
        document.body.classList.add("hero-hidden");
        document.body.classList.remove("hero-visible");
      }
    };
  
    const heroSection = document.getElementById("hero-section");
    if (heroSection) {
      window.addEventListener("scroll", handleScroll);
      handleScroll(); // Initial check
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    } else {
      document.body.classList.add("hero-hidden");
      document.body.classList.remove("hero-visible");
    }
  }, []);
  
  return (
    <Layout>
      <ThemeColorSetter color={themeColor} colorBg={themeColorBg} />
    <main>
      <Header bgColor={projectBgColor} textColor={projectTextColor} />
      <Hero
        projectType={"Case study"}
        productType={"Headless, Multi-brand, Design System"}
        title={"eCOMMERCE"}
        titleRow={"PLATFORM"}
        subtitle={"One code, diverse outcomes - tailored solutions for every brand"}
        projectBg={theme?.colors?.accellProjectBgECom as string}
        projectText={theme?.colors?.accellProjectTextECom as string}
        shortDesc={
          <Fragment>
            Accell's eCommerce platform is built with Object-Oriented UX at its core and enhanced by a robust Design System. It scales efficiently using Design Tokens and a comprehensive brand configuration system, aiming to elevate the online presence and sales of its brands.
          </Fragment>
        }
        year={"2019 - 2021"}
        company={"ACCELL"}
        role={
          <Fragment>
            Strategy Design Systems <br />
            Design + Execution <br /> UI Engineering <br />
            Research
          </Fragment>
        }
        roleDesc={
          <Fragment>
            As part of the UX Design team, I took part in daily operations
            efforts to implement a Headless eCommerce platform. My role shifted
            from support to a more active one:{" "}
            <strong>filling the gap between designers and developers.</strong>{" "}
            While career wise my preferred choice would have been to be more of
            a UX generalist designer, due to capacity, I had to focus on design
            systems. As much as work-life balance allowed, I took challenges and
            tasks in evangelizing and practicing the Object Oriented UX
            framework. Also took some great joy in conducting a competitive
            analysis regarding keywords used in the main navigation.
          </Fragment>
        }
        disciplines={
          <Fragment>
            Branding &mdash; Content &mdash; CRM &mdash; Design &mdash;
            Development &mdash; Marketing &mdash; SEO
          </Fragment>
        }
        challenge={
          <Fragment>
            Our small team needed to be successful{" "}
            <strong>
              implementing the proof of concept of a Headless eCommerce
              Platform*
            </strong>
            . In addition we had to consolidate relationships with the teams
            responsible for managing brands. Our challenge was to evolve with
            customers demands and enter the highly competitive directly to
            consumer market. It had to be good, fast and at a low cost. <br />
            <strong>* </strong>
            Designed in house and developed with an external agency.
          </Fragment>
        }
        component={
          <BookScene
            coverColor={"#f0c844"}
            textColor={"silver"}
            urlTexture={"/models/textures/ecomm-book-cover.jpg"}
          />
        }
      />
      {/* <BoxScene coverColor={theme?.colors?.coverBook as string } textColor={theme?.colors?.bigTitle as string } urlTexture={'/models/textures/p_box_set_01.003_2.jpg'} /> */}

      <Flex
        flexWrap="wrap"
        justifyContent="center"
        variant="container"
        id="project-content"
      >
        <Box variant="containerTxt">
          <Heading as="h6" variant="catHeading">
            User story
          </Heading>
          <Text as="p" pb={[3, 4]} mb={[4, 5]} variant="paragraphXL">
            <strong>As an</strong> owner of multiple brands
            <br />
            <strong>I want to</strong> have a single platform to handle all the
            online shops
            <br />
            <strong>So that</strong> all brands can go directly to customer and
            expand into international markets.
          </Text>
          <Heading as="h3" variant="bigTitleXS">
            Why B2C?
          </Heading>
          <ul>
            <li>
              <strong>Online to accelerate growth</strong> &mdash; reduces the
              complexity of launching and maintaining more than one brand.
            </li>
            <li>
              <strong>Cost effective</strong> &mdash; removing the middleman
              means companies can experiment with branding and get to market
              quicker.
            </li>
            <li>
              <strong>Speed</strong> &mdash; direct interaction allows to
              collect data and address issues without intermediaries in an agile
              manner to benefit both business and consumers.
            </li>
            <li>
              <strong>Brand visibility</strong> &mdash; improves customer
              experience by providing more relevant content.
            </li>
          </ul>
          <Heading as="h3" variant="bigTitleXS" mt={[5]}>
            A Test-and-Learn <br />
            Approach
          </Heading>
          <Text as="p" mb={[2, 3, 4]}>
            Given the diversity of brands and their specific target audiences we
            needed to offer different solutions using the same code to showcase
            content.
          </Text>
          <Text as="p" mb={[2, 3, 4]}>
            <strong>Think of it this way: </strong> it’s nearly impossible for
            teams to be the best at everything, so we needed a way to{" "}
            <strong>align our priorities</strong> and focus on delivering the
            best possible experience in the areas that matter the most to our
            users.
            <br />
            User Experience is about how it works when a person comes in contact
            with it and creating one takes continuous effort.
          </Text>
          <Text as="p" mb={[2, 3, 4]}>
            <strong>Less is more: </strong> We focused on designing and
            implementing best e-commerce practices, consolidated by UX research
            and UI patterns while working within cross functional teams. The
            storefronts of <strong>Raleigh</strong> and{" "}
            <strong>Lapierre</strong> are mobile-first scalable, fast, and
            optimized for conversions. This gives you complete control over the
            customer's experience in systems that can easily evolve, yet remain
            consistent.
          </Text>
          <Text as="p" mb={[1, 2]}>
            We achieved this with:
          </Text>
          <ul>
            <li>
              <strong>Scaling design systems</strong>
            </li>
            <li>
              <strong>Best design practices</strong>
            </li>
            <li>
              <strong>Easy shopping experience</strong>
            </li>
            <li>
              <strong>Headless eCommerce Platform</strong>
            </li>
          </ul>
        </Box>
      </Flex>

      {/* Customer Insights */}
      <Flex flexWrap="wrap" variant="container" justifyContent="center">
        <Box width={[1 / 1, 7 / 12]} variant="containerBigTitle">
          <ProjectFadeInTitle
            word1={"Customer"}
            word1ClassName={"outline uppercase"}
            word3={"Insights"}
            word3ClassName={"uppercase"}
          />
        </Box>
        <Box width={[1 / 1, 5 / 12]} variant="containerBigTitleImg">
          <Heading as="h3" variant="catHeading">
            Rating
          </Heading>
          <CustomerInsights />
        </Box>
        <Box width={[1 / 1]} variant="containerTxt">
          <Text as="span" variant="caption"></Text>
          <Heading as="h3" variant="catHeading">
            Qualitative data
          </Heading>
          <Text as="p" mb={[1, 2]}>
            Based on quality data and marketing reports, customers faced
            recurring challenges when purchasing bikes online. We needed to
            address behavior, motivations and test outcome:
          </Text>
          <ul>
            <li>
              <strong>Which bike is right for me?</strong>
            </li>
            <li>
              <strong>What frame should I choose?</strong>
            </li>
            <li>
              <strong>What's my size?</strong>
            </li>
            <li>
              <strong>What specifications should my bike have?</strong>
            </li>
            <li>
              <strong>Where can I test it?</strong>
            </li>
            <li>
              During the pandemic the most important question became: <br />
              <strong>What bikes are in stock?</strong>
            </li>
          </ul>
          <Heading as="h2" mt={[5]} variant="bigTitleXS">
            What We Knew
          </Heading>
          <Text as="p">
            <strong>Slow pace decision</strong>: It can take customers from 6 to
            8 months to decide to purchase a new bike.
            <br />
            <strong>Test before buy:</strong> They would prefer to test it
            before purchase.
            <br />
            This meant that our online store also needed to offer great support
            for dealers. Some brands even refused to have an online shop and
            used the platform solely to help the customer be informed about
            collections, relying on dealers to make sells.
          </Text>
        </Box>
      </Flex>
      {/* Persona, Sport vs. Lifestyle */}
      <Flex flexWrap="wrap" variant="container" justifyContent="center">
        <Box width={[1 / 1, 1 / 2]} pr={[0, 4]} mt={[3, 4, 5]}>
          <Box variant="wrapperImg">
            <StaticImage
              src="../../../img/ecommerce/sport-persona.jpg"
              alt="Sports persona"
            />
          </Box>
        </Box>
        <Box width={[1 / 1, 1 / 2]} pl={[0, 4]} mt={[3, 4, 5]}>
          <Box variant="wrapperImg">
            <StaticImage
              src="../../../img/ecommerce/lifestyle-persona.jpg"
              alt="Lifestyle persona"
            />
          </Box>
        </Box>
        <Box width={[1 / 1]} variant="containerTxt" mt={[5]}>
          <Heading as="h2" variant="bigTitleXS">
            Sport vs. Lifestyle
          </Heading>
          <Text as="p" mb={[1, 2]}>
            Based on target audience, their motivations and specific needs, we
            decided for the following categorization (there are brands that have
            both)
          </Text>
          <ul>
            <li>
              <strong>Sport: </strong>
              <ul>
                <li>base of customers ranging from ages 45 to 55, 80% male</li>
                <li>
                  high interest in performance, latest technology and frame
                  design, competitions and famous athletes
                </li>
                <li>use for sport and leisure, have fun in nature</li>
                <li>a sense of luxury and exotic locations</li>
              </ul>
            </li>
            <li>
              <strong>Lifestyle: </strong>
              <ul>
                <li>
                  wider base of customers from 25 to 65, 60% male, 40% female
                </li>
                <li>
                  interest in price, color, practicality, functionality, eBikes,
                  comfort
                </li>
                <li>use for commute</li>
                <li>a sense of affordable and city locations</li>
              </ul>
            </li>
          </ul>
          <Heading as="h3" variant="catHeading" mt={[5]}>
            Play the part
          </Heading>
          <Text as="p">
            In order to{" "}
            <strong>
              support my team express ideas, design principles and drive
              decision making
            </strong>
            , I translated and thoroughly considered customer insights to
            concepts that addressed customer motivations. I created prototypes
            to share design best practices that supported content strategy,
            brand requirements and technical specifications. UX Research reports
            were carefully documented and opened for feedback which increased
            the level of understanding of the design discipline.
          </Text>
        </Box>
      </Flex>

      {/* Scaling Design Systems for Multi-Brand */}
      <Flex flexWrap="wrap" justifyContent="center" variant="container">
        <Box width={[1 / 1]} variant="containerBigTitle">
          <ProjectFadeInTitle
            word1={"Design "}
            word1ClassName={"uppercase"}
            word2={"Systems"}
            word2ClassName={"uppercase outline"}
            word4={"for MULTI-BRANDS"}
          />
        </Box>
        <Box width={[1 / 1]} variant="containerTxt" mt={[5, 6]}>
          <Text as="p" mb={[5]}>
            One of the main focuses of our platform was making sure the content,
            UI and visuals for each audience was customized and curated to what
            they needed throughout the entire customer journey.
          </Text>
          {/* Multi-Brand Configuration System */}
          <Heading as="h3" variant="catHeading" mt={[3, 4, 5]}>
            Configuration System
          </Heading>
          <Heading as="h2" variant="bigTitleXS">
            Design Tokens and Theming
          </Heading>
          <Text as="p">
            The platform needed to work across brands. To make the desired
            styling happen we made very good use of design tokens and
            implemented theming.
            <br />
            <br />
            <strong>Theming</strong> is applied on two levels:
          </Text>
          <ul sx={{ marginBlockStart: "0px" }}>
            <li>a theme for each brand</li>
            <li>dark / light theming per UI Component</li>
          </ul>
          <Text as="p" mb={[1, 2]}>
            Brand themes are generated through design tokens using &nbsp;
            <Link
              variant="aLink"
              href="https://github.com/amzn/style-dictionary"
              target="_blank"
              rel="noopener norefferer"
              mb={[1, 2]}
            >
              Style Dictionary.
            </Link>
          </Text>
          <Box variant="wrapperImg" mt={[5]} mb={[3]}>
            <StaticImage
              src="../../../img/ecommerce/ecom-theming-table-values.png"
              alt="Design Tokens Values table"
            />
          </Box>
          <Text as="span" mb={[5]}>
            <strong>Design tokens</strong> values can be changed by editing the
            JSON files and are expected to have the same properties and
            structure for each brand. The files under "global" are different and
            contain the design tokens that have the same values for every brand.
            The brand tokens will be merged with the global tokens and flattened
            to create the theme.
          </Text>
          <Heading as="h4" variant="catHeading">
            Important Note
          </Heading>
          <Text as="p" mb={[5, 6]}>
            <strong>
              The configuration system does more than handle styling wishes.
            </strong>{" "}
            It also allows for layout changes, localization, managing different
            payment systems, etc. Was a pleasure working with Oberon's team of
            Front-End experts.
          </Text>
        </Box>
      </Flex>

      <Carousel
        img1={imgCover1}
        img2={imgCover2}
        img3={imgCover3}
        img4={imgCover4}
        img5={imgCover5}
      />

      {/* Relevant content */}
      {/* <Flex
        flexWrap="wrap"
        justifyContent="center"
        variant="container"
        py={[3, 4]}
        id="project-content"
      >
        <Box variant="containerTxt">
          <Heading as="h3" variant="catHeading" mt={[3, 4, 5]}>
            Design Principles
          </Heading>
          <Heading as="h2" variant="bigTitleXS">
            Relevant Content
          </Heading>
          <Text as="p" mb={[1, 2]}>
            A Harvard study showed that after just 50 milliseconds, a visitor to
            your website has drawn a conclusion about your site's
            professionalism. This is why the design and appearance of your
            digital storefront matters — it only takes one look for someone to
            decide if they'll stay or bounce.
          </Text>
          <ul sx={{ mb: [3, 4, 5] }}>
            <li>
              <strong>Product categories</strong> to display crucial information
            </li>
            <li>
              <strong>Organization of search results and queries</strong>{" "}
              (Storyblok and Algolia integration)
            </li>
            <li>
              <strong>Mobile first responsive design</strong>
            </li>
            <li>
              <strong>Customer reviews</strong>
            </li>
            <li>
              <strong>Distribution</strong> (Store locators)
            </li>
          </ul>
        </Box>
      </Flex> */}

      <Flex flexWrap="wrap" variant="container" justifyContent="center">
        <Box width={[1 / 1]} variant="containerTxt">
          <Text as="p" my={[5, 6]}>
            Our team was highly flexible and was able to design and build
            components that fit together and are flexible enough to be re-used.
            Design process can at times seem chaotic. With a design system
            ready, I was able to quikly prototype and test my ideas.
          </Text>
        </Box>
      </Flex>

      <Flex
        flexWrap="wrap"
        justifyContent="center"
        variant="container"
        mb={[5]}
      >
        <Box width={[1 / 1, 1 / 2]} pr={[0, 4]} textAlign="right" mb={[3, 4]}>
          <Flex flexWrap="wrap" justifyContent="flex-end">
            <Box
              width={[1 / 2]}
              mb={[5, 6]}
              mr={[0, 0, 3, 4]}
              variant="containerSmallSreen"
            >
              <StaticImage
                src="../../../img/ecommerce/ecomm-lapierre-oproduct-full-hero.jpg"
                alt="Lapierre - product detail page"
              />
            </Box>
            <Box width={[1 / 2]} mb={[3, 4]} variant="containerSmallSreen">
              <StaticImage
                src="../../../img/ecommerce/ecom-lapierre-oproduct-full-product-gallery.jpg"
                alt="Lapierre - product detail page"
              />
            </Box>
          </Flex>
          <StaticImage
            src="../../../img/ecommerce/ecom-lapierre-oproduct-full.jpg"
            alt="Lapierre - product detail page"
          />
        </Box>
        <Box width={[1 / 1, 1 / 2]} pl={[0, 4]}>
          <Flex flexWrap="wrap" justifyContent="center">
            <Box
              width={[1 / 2]}
              mb={[5, 6]}
              mr={[0, 3, 4]}
              variant="containerSmallSreen"
            >
              <StaticImage
                src="../../../img/ecommerce/ecom-raleigh-oproduct-full-small-screen.jpg"
                alt="Lapierre - product detail page"
              />
            </Box>
            <Box
              width={[1 / 2]}
              mb={[3, 4]}
              mr={[0, 3, 4]}
              variant="containerSmallSreen"
            >
              <img src={UCarouselZoom} alt="Carousel Zoom prototype" />
            </Box>
            <Box>
              <Heading as="h3" variant="catHeading">
                Quick prototyping
              </Heading>
              <Text as="span" variant="caption">
                <strong>Test and iterate</strong> &mdash; prototpying helps
                visualize and think about interaction and motion.
              </Text>
            </Box>
          </Flex>
          <StaticImage
            src="../../../img/ecommerce/ecom-raleigh-oproduct-full.jpg"
            alt="Lapierre homepage"
          />
        </Box>
      </Flex>

      {/* Best Design Practices */}
      <Flex flexWrap="wrap" justifyContent="center" variant="container">
        <Box width={[1 / 1, 7 / 12]} variant="containerBigTitle">
          <ProjectFadeInTitle
            word1={"Best"}
            word2={"Design"}
            word2ClassName={"outline"}
            word3={"practices"}
            className={"uppercase"}
          />
        </Box>
        <Box width={[1 / 1, 5 / 12]} variant="containerBigTitleImg">
          <EcomBestDesignPractices />
        </Box>
        <Box width={[1 / 1]} mb={[4]} variant="containerTxt">
          <Heading as="h3" variant="catHeading">
            UI & Object Components
          </Heading>
          <Heading as="h2" variant="bigTitleXS">
            Ecosystem <br />
            Across Disciplines
          </Heading>

          <Text as="p" mb={[1, 2, 3]}>
            For all our brands we apply the principles of Object Oriented UX
            (OOUX). It is a framework in which we think about core content and
            data as objects instead of artifacts we would normally use such as
            pages.
            <br />
            Our Design Systems offers structure to build an ecosystem in which
            all disciplines involved in providing a usable customer experience
            can thrive.
          </Text>
          <Text as="p" mb={[1]}>
            <strong>Naming conventions</strong> can differ in their intents, but
            one of their main perks is that they allow useful information to be
            deduced from their names based on regularities. Working across
            disciplines provides the team benefits such as:
          </Text>
          <ul>
            <li>
              Provides <strong>additional information</strong>
            </li>
            <li>
              Helps <strong>formalize expectations</strong>
            </li>
            <li>
              Promotes <strong>consistency</strong> within development, content
              and design teams
            </li>
            <li>
              Provides <strong>clarity</strong> and better understanding for on
              boarding new team members as the product grows
            </li>
            <li>
              Enhances the <strong>professional look</strong> of the work of one
              product
            </li>
          </ul>

          <Heading as="h2" variant="bigTitleXS" mt={[5]}>
            Patterns
          </Heading>
          <Text as="p" mb={[5, 6]}>
            Patterns are best practice solutions for how a user achieves a goal.
            They show reusable combinations of components, objects and templates
            that address common user objectives with sequences and flows.
          </Text>
          <Box width={[1 / 1]} variant="wrapperImg">
            <StaticImage
              src="../../../img/ds/ds-pattern-global-header.png"
              alt="Design System - Pattern - Global Header"
            />
          </Box>
        </Box>
      </Flex>

      {/* Easy Shopping Experience   */}
      <Flex flexWrap="wrap" justifyContent="center" variant="container">
        <Box width={[1 / 1]} variant="containerBigTitle">
          <ProjectFadeInTitle
            word1={"Easy"}
            word1ClassName={"outline"}
            word2={"Shopping"}
            word3={"Experience"}
            className={"uppercase"}
          />
        </Box>
        <Box width={[1 / 1]} my={[3, 4, 5]} variant="containerTxt">
          <Text as="p" mb={[1, 2]}>
            Collecting products in one cart makes for an easier shopping
            experience. One of the most common places in the sales funnel where
            companies loose customers is the checkout. This is often due to the
            fact that the process is too long or too complicated.
            <br />
            On top of this the customer needs to be able to find the desired
            product. To account for these known issues our offerings benefit
            from:
          </Text>
          <ul>
            <li>
              <strong>Discoverability</strong> via our search, product listing
              pages and filtering
            </li>
            <li>
              <strong>Visibility</strong> via our campaign, product line and
              product detail pages object components
            </li>
            <li>
              <strong>Easy purchase</strong> that integrates contact details,
              shipping methods, billing and basket overview
            </li>
            <li>
              <strong>Transparency</strong> of contact and payment methods
            </li>
            <li>
              <strong>Feedback</strong>
            </li>
            <li>
              <strong>Easy maintenance</strong> for content managers
            </li>
          </ul>
          <Flex flexWrap="wrap" justifyContent="center" alignItems="Center">
            <Box
              width={[1 / 1]}
              my={[3, 4, 5]}
              mr={[0, 0, 3, 4]}
              variant="containerSmallSreen"
            >
              <img src={LPFilterPattern} alt="Filter Pattern " />
            </Box>
            <Box width={[1 / 2]} ml={[0, 3, 4]}>
              <StaticImage
                src="../../../img/ecommerce/raleigh-2-devices-front-right-light.png"
                alt=""
              />
            </Box>
          </Flex>
        </Box>
      </Flex>

      {/* Basket Object */}
      <Flex
        flexWrap="wrap"
        justifyContent="center"
        alignItems="center"
        variant="container"
      >
        <Box width={[1 / 1]} variant="containerTxt">
          <Heading as="h2" variant="bigTitleXS">
            Basket
          </Heading>
          <Text as="p" mb={[2, 4]}>
            A basket is a set of products, can be a combination of bike(s) and/
            or other products or services that we sell) a customer has selected
            and 'put in their basket' in order to purchase them.
          </Text>
        </Box>
        <Box width={[1 / 1]}>
          <Flex
            flexWrap="wrap"
            justifyContent="center"
            alignItems="top"
            variant="container"
          >
            <Box width={[1 / 2, 1 / 2, 3 / 12]} px={[3, 4, 5, 6]}>
              <StaticImage
                src="../../../img/lapierre/LP-OBasketFull.png"
                alt="Lapierre - OBasketFull"
              />
            </Box>
            <Box width={[1 / 2, 1 / 2, 3 / 12]} px={[3, 4, 5, 6]} ml={[-4, -6]}>
              <StaticImage
                src="../../../img/lapierre/LP-OBasketFull-scrolled.png"
                alt="Lapierre - OBasketFull Scrolled"
              />
            </Box>
            <Box width={[1 / 1, 1 / 2, 6 / 12]} pt={[2, 4]}>
              <StaticImage
                src="../../../img/lapierre/LP-OBasketFull-large-screen.jpg"
                alt="Lapierre - Category listing and filter"
              />
              <Text as="span" variant="caption">
                <strong>The basket object</strong> as seen on large screens for
                the Lapierre brand.
              </Text>
              {/* <StaticImage
                src="../../../img/lapierre/LP-OBasketL-large-screen-checkout.jpg"
                alt="Lapierre - Category listing and filter"
              /> */}
            </Box>
          </Flex>
        </Box>
      </Flex>

      {/* Headless eCommerce */}
      <Flex flexWrap="wrap" justifyContent="center" variant="container">
        <Box width={[1 / 1, 7 / 12]} variant="containerBigTitle">
          <ProjectFadeInTitle
            word1={"Headless"}
            word1ClassName={"uppercase"}
            word3={"eCOMMERCE"}
          />
        </Box>
        <Box width={[1 / 1, 5 / 12]} variant="containerBigTitleImg">
          <Heading as="h3" variant="catHeading">
            It's a good thing
          </Heading>
          <EcomPlatform />
          <Text as="span" variant="caption">
            "Headless commerce is an e-commerce architecture where the front-end
            (head) is decoupled from the back-end commerce functionality and can
            thus be updated or edited without interfering with the back-end, ...
            ." &mdash; Wikipedia
          </Text>
        </Box>
        <Box width={[1 / 1]} mb={[5]} variant="containerTxt">
          <Heading as="h3" variant="catHeading"></Heading>
          <Heading as="h3" variant="bigTitleXS">
            Benefits
          </Heading>
          <ul>
            <li>
              Easy to maintain and develop at <strong>lower costs</strong>.
            </li>
            <li>
              Separating the presentation layer from the data and logic layer
              allows to <strong>restructure the content at any time.</strong>
            </li>
            <li>
              <strong>Fast development</strong> choose any API, framework or
              programming language. If the choice is proven to be wrong you can
              always switch it without re-building the entire platform.
            </li>
          </ul>

          <Heading as="h2" variant="bigTitleXS" mt={[5]}>
            Customizable <br />
            and entirely CMS-able
          </Heading>
          <Text as="p" mb={[1, 2]}>
            Working within cross functional teams was an essential part of our
            design approach. Not only we needed to serve multiple brands and
            their needs, we also needed to design rules of usage that work
            across disciplines that make sense, are understood and easy to
            maintain.
          </Text>
          <Text as="p" mb={[5, 6]}>
            Build on <strong>Gatsby</strong>, with <strong>Storyblok</strong> as
            a content management system and <strong>Shopware</strong> as the
            chosen eCommerce solution. In sync, as well as part of our Design
            System, we also have a <strong>Storybook</strong> library of Object
            and UI Components.
          </Text>
          <Heading as="h3" variant="catHeading" mt={[5]}>
            The End
          </Heading>
          <Heading as="h3" variant="bigTitleXS">
            Deliverables
          </Heading>
          <ul>
            <li>
              Design Systems for&nbsp;
              <Link
                variant="aLink"
                href="https://zeroheight.com/2a0d91f6c/p/738ed7-raleigh"
                target="_blank"
                rel="noopener norefferer"
                mb={"0px"}
              >
                Raleigh
              </Link>
              &nbsp;&amp;&nbsp;
              <Link
                variant="aLink"
                href="https://zeroheight.com/6ebd1dfb8/p/9979d8-lapierre"
                target="_blank"
                rel="noopener norefferer"
                mb={"0px"}
              >
                Lapierre
              </Link>
            </li>
            <li>Documentation</li>
            <li>UI Libraries in Sketch</li>
            <li>OneDrive setup (for assets management)</li>
            <li>Code (for UI Components)</li>
            <li>Design Tokens</li>
            <li>Prototypes</li>
            <li>User Flows</li>
          </ul>
          <Heading as="h3" variant="catHeading" mt={[5]}>
            Personal Note
          </Heading>
          <Heading as="h2" variant="bigTitleXS">
            The Take Away
          </Heading>
          <Text as="p">
            Lack of a common goal lead to the launch being delayed. Capacity led
            to coordination challenges within teams. In order to be effective I
            worked closely with front-end, content, SEO and design. I was
            support on a daily bases to ensure completion of components.
            <br />
            The UX Design team had a more linear approach in the way of working,
            with UX Research leading and UI, Content and Development to follow.
            This led to the process of decision making being at times one sided,
            but it helped with timelines and prioritization. I expressed my
            concerned that collaboration and communication are vital to a team’s
            performance.
            <br />
            Design was included into an engineering-driven process. This meant
            that the time to create designs was the time left-over.
          </Text>
          <Heading as="h3" variant="catHeading" mt={[3, 4, 5]} mb={[2]}>
            Out there
          </Heading>
          <Link
            variant="buttons.primary"
            href="https://www.lapierrebikes.com"
            target="_blank"
            rel="noopener norefferer"
            py={[3]}
            my={[2, 3, 4]}
            mr={[3]}
          >
            View Lapierre.com
          </Link>

          <Link
            variant="buttons.primary"
            href="https://www.raleigh.co.uk"
            target="_blank"
            rel="noopener norefferer"
            py={[3]}
            my={[0, 2, 3, 4]}
          >
            View Raleigh.co.uk
          </Link>
        </Box>
      </Flex>
      <CompanyProjects title="ACCELL" />
      <ProjectTitleListing />
      <BackToTop />
    <Footer />
      </main>
    </Layout>
  );
}
export default Project;
